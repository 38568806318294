












import { Component, Vue } from 'vue-property-decorator';

import MainHeader from '@/shared/resources/components/MainHeader.vue';
import QRReader from '@/shared/resources/components/qr-reader/QRReader.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import QRCodeModel from '@/app/modules/qr-codes/models/QRCodeModel';
import ApiORMQueryBuilder from '@/core/bridge/orm/api/ApiORMQueryBuilder';

@Component({
  components: {
    GridCol,
    GridRow,
    QRReader,
    MainHeader,
  },
})
export default class QRReaderView extends Vue {
  /**
   * Data
   */
  private query: ApiORMQueryBuilder = QRCodeModel.apiExt();
}
